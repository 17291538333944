export const OPD_CONST = {
  reportPayload:"reportPayload",
  reportPayload2:"reportPayload2",
  reportPayload3:"reportPayload3",
  patientManagement:"Patient_Management",
  durations: [{ value: "Days" }, { value: "months" }],
  displayColumnEncounters: [
    "VisitDate",
    "visitType",
    "VisitNumber",
    "Department",
    "DoctorName",
    "payer"
    // "BillNumber",
    // "BillAmount",
    // "OutstandingAmount",
  ],
  displayEncounterList: [
    // "Print",
    // "Serial",
    "MRN",
    "EncounterNumber",
    "PatientName",
    "Gender/Age",
    "VisitDate",
    "DepartmentName",
    "DoctorName",
    "AppointmentDate",
    "EncounterToken",
    "glStatus",
    "Status",
    "checkout",
  ],
  displayColumnAdmissions: [
    "VisitDate",
    "VisitNumber",
    "Department",
    "DoctorName",
    "Ward",
    "Bed",
  ],
  reamarksList: ["notes", "addedBy", "noteDate"],
  displayColumnPayers: [
    "Priority",
    "PayerType",
    "Payer",
    "Tariff",
    "Contract",
    "AssociatedCompany",
    "gLRefNo",
    "GLType",
    "PolicyNumber",
    "EffectiveDate",
    "expiryDate",
    "glBalance",
    "Action",
  ],
  displayColumnNok: [
    "check",
    "Name",
    "Relation",
    "IdentificationType",
    "IdentificationNo",
    "Mobile",
    "IsGuarator",
    "Action",
  ],
  fileUploadColumns: [
    "docType",
    "docName",
    "fileName",
    "fileType",
    "action",
  ],
  healthTouristColumns:[
    "touristCode",
    "identificationType",
    "identificationNo",
    "agentName",
    "startEndDate",
    "contactNo",
    "emailId",
    "addedOn",
    "action"
  ],
  displayColumnBlock: [
    "photo",
    "MRN",
    "name",
    "idtype",
    "idnumber",
    "gender",
    "contact",
    "dob",
  ],
  displayedColumnsBlockList: [
    "radio",
    "mrn",
    "patientDetails",
    "identificationType",
    "identificationNumber",
    "contactNumber",
    "isCTOS",
    "reason",
    "remarks",
    "status",
    "blockingBy",
    "blockingDate",
    "history",
  ],
  displayColumnsHistoryBlockList: [
    "BlockStatus",
    "BlockingDate",
    "BlockingBy",
    "BlockingReason",
    "BlockingRemarks",
  ],
  displayBlockStatus: {
    blocked: "Blocked",
    ctosBlocked: "CTOS Blocked",
    unBlocked: "UnBlocked",
  },
  displayHeaderColumn: ["PatientDetails"],
  splRightCancelCode:"SP02",
  splRightPatient:"SP04",
  splRightFreeze:"SP05",
  splRightUnFreeze:"SP06",
  primaryIdRegix: "[A-Za-z0-9-]*",
  faxRegex: "[0-9-]*",
  idTypePassportValue: "Passport",

  // idTypeWorkPermitValue:"Work Permit",
  // idTypeBirthValue:"Birth Certificate",
  // idTypeNewICValue:"New IC",
  idTypemykadValue: "002",
  reasonTypeMaster: 1,
  filterType: "patient",
  encounter: "Encounter",
  departmentText: "Department_Text",
  imagePath: "../assets/images/user.png",
  docSearchType: [
    { code: "01", desc: "Doctor ID" },
    { code: "02", desc: "Doctor Name" },
  ],
  referralDisplayColumn: [
    "selectDoctor",
    "doctorId",
    "doctorName",
    "speciality",
    "location",
    "address",
    "phone",
  ],
  patientIdetifier: [
    {
      system: "EN",
      code: "PPI",
      display: "Patient Identifier",
    },
  ],
  encounterSave: {
    status: "ARRIVED",
    businessStatus: "business",
    active: "Active",
    id: "ID",
  },
  visitType: {
    system: "EN",
    code: "OP",
    display: "OP",
  },
  erVisittype:{
    system: "EN",
    code: "ER",
    display: "ER",
  },
  financialList: [
    {
      system: "EN",
      code: "OP",
      display: "OP",
    },
  ],
  erFinancialList: [
    {
      system: "EN",
      code: "ER",
      display: "ER",
    },
  ],
  appoinmentRef: {
    aptData: "appointmentData",
    temp: "TEMP",
    reg: "REG",
    aptResData: "appointmentReschedule",
    registred: "registered",
    myKad: "MYKAD",
    extendedPatient: "ExtendedPatient",
    fileAttachment: "fileAttachment",
    imageData: "data:image/jpg;base64,",
    appointmentEncounter:"appointmentEncounter"
  },
  displayColumnMerge: [
    "mrn",
    "patientDetails",
    "dob",
    "idtype",
    "idnumber",
    "mobileno",
    "action",
  ],
  displayColumnMergeList: [
    "radio",
    "primarymrn",
    "primaryPatientDetails",
    "secondarymrn",
    "secondaryPatientDetails",
    "mergedReason",
    "unMergedReason",
    "mergedBy",
    "unMergedBy",
    "status",
    "action",
  ],
  displaypatientList: [
    "photo",
    "MRN",
    "name",
    "idtype",
    "idnumber",
    "gender",
    "contact",
    "dob",
  ],

  aptref: {
    agendaWeek: "agendaWeek",
    checkbox: "checkboxMode",
    time: "hh:mm a",
    off: "Off",
    appt: "appt",
    quickInfo: "QuickInfo",
    editor: "Editor",
    Excel: "Excel",
    rescheduleApp: "rescheduleApp",
    clinicCode: "CC",
    aptTypeCode: "1",
    apttypeDeptCode: "2",
    apttypeDeptDesc:"Department",
    apttTypeHscCode:"3",
    apttTypeHscDesc:"HSC",
    dateFormat: "dd/MM/yyyy",
    fcCenter: "fc-center",
    resource: "resource",
    jsDateFormat: "dd/MM/yyyy",
    utc: "UTC",
    selectedDate: "selectedDate",
    slotMints: "12",
    width: "80pc",
    dialogWidth: "450px",
    active: "active",
    new: "new",
    selected: "selected",
    past: "past",
    blocked: "blocked",
    mobile: "mobile",
    home: "home",
    work: "work",
  },
  specialReqirements: [
    { system: "EN", code: "01", display: "Need vip treatment" },
    { system: "EN", code: "02", display: "Require Wheel Chair" },
    { system: "EN", code: "03", display: "Prescreening" },
  ],
  specialReqirementsMY: [
    { system: "EN", code: "01", display: "Perlukan rawatan vip" },
    { system: "EN", code: "02", display: "Memerlukan Kerusi Roda" },
    { system: "EN", code: "03", display: "Prapenyaringan" },
  ],
  aptResource: [
    { CalendarText: "Available", CalendarId: 1, CalendarColor: "#B6ECB6" },
    { CalendarText: "Unavailable", CalendarId: 2, CalendarColor: "#dddddd" },
    { CalendarText: "Booked", CalendarId: 3, CalendarColor: "#F5A0A0" },
    { CalendarText: "Blocked", CalendarId: 4, CalendarColor: "#87bdeb" },
  ],
  aptItemModel: [
    { text: "iCalendar", iconCss: "e-icons e-export" },
    { text: "Excel", iconCss: "e-icons e-export-excel" },
  ],
  aptRecords: { text: "text", value: "value" },
  aptcalenderFields: { text: "CalendarText", value: "CalendarId" },
  aptTimeSlotCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  aptWorkDays: [1, 2, 3, 4, 5],
  aptweekDays: [
    { text: "Sunday", value: 0 },
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thursday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
  ],
  aptTimeZone: [
    { text: "UTC -12:00", value: "Etc/GMT+12" },
    { text: "UTC -11:00", value: "Etc/GMT+11" },
    { text: "UTC -10:00", value: "Etc/GMT+10" },
    { text: "UTC -09:00", value: "Etc/GMT+9" },
    { text: "UTC -08:00", value: "Etc/GMT+8" },
    { text: "UTC -07:00", value: "Etc/GMT+7" },
    { text: "UTC -06:00", value: "Etc/GMT+6" },
    { text: "UTC -05:00", value: "Etc/GMT+5" },
    { text: "UTC -04:00", value: "Etc/GMT+4" },
    { text: "UTC -03:00", value: "Etc/GMT+3" },
    { text: "UTC -02:00", value: "Etc/GMT+2" },
    { text: "UTC -01:00", value: "Etc/GMT+1" },
    { text: "UTC +00:00", value: "Etc/GMT" },
    { text: "UTC +01:00", value: "Etc/GMT-1" },
    { text: "UTC +02:00", value: "Etc/GMT-2" },
    { text: "UTC +03:00", value: "Etc/GMT-3" },
    { text: "UTC +04:00", value: "Etc/GMT-4" },
    { text: "UTC +05:00", value: "Etc/GMT-5" },
    { text: "UTC +05:30", value: "Asia/Calcutta" },
    { text: "UTC +06:00", value: "Etc/GMT-6" },
    { text: "UTC +07:00", value: "Etc/GMT-7" },
    { text: "UTC +08:00", value: "Etc/GMT-8" },
    { text: "UTC +09:00", value: "Etc/GMT-9" },
    { text: "UTC +10:00", value: "Etc/GMT-10" },
    { text: "UTC +11:00", value: "Etc/GMT-11" },
    { text: "UTC +12:00", value: "Etc/GMT-12" },
    { text: "UTC +13:00", value: "Etc/GMT-13" },
    { text: "UTC +14:00", value: "Etc/GMT-14" },
  ],
  aptTimeSlotDuration: [
    { Name: "10 min", Value: 10 },
    { Name: "20 min", Value: 20 },
    { Name: "1 hour", Value: 60 },
    { Name: "1.5 hours", Value: 90 },
    { Name: "2 hours", Value: 120 },
    { Name: "2.5 hours", Value: 150 },
    { Name: "3 hours", Value: 180 },
    { Name: "3.5 hours", Value: 210 },
    { Name: "4 hours", Value: 240 },
    { Name: "4.5 hours", Value: 270 },
    { Name: "5 hours", Value: 300 },
    { Name: "5.5 hours", Value: 330 },
    { Name: "6 hours", Value: 360 },
    { Name: "6.5 hours", Value: 390 },
    { Name: "7 hours", Value: 420 },
    { Name: "7.5 hours", Value: 450 },
    { Name: "8 hours", Value: 480 },
    { Name: "8.5 hours", Value: 510 },
    { Name: "9 hours", Value: 540 },
    { Name: "9.5 hours", Value: 570 },
    { Name: "10 hours", Value: 600 },
    { Name: "10.5 hours", Value: 630 },
    { Name: "11 hours", Value: 660 },
    { Name: "11.5 hours", Value: 690 },
    { Name: "12 hours", Value: 720 },
  ],
  aptTimeSlotFields: { text: "Name", value: "Value" },
  aptTimeFormatData: [
    { Name: "12 hours", Value: "hh:mm a" },
    { Name: "24 hours", Value: "HH:mm" },
  ],
  aptWeekNumberValue: [
    { Name: "Off", Value: "Off" },
    { Name: "First Day of Year", Value: "FirstDay" },
    { Name: "First Full Week", Value: "FirstFullWeek" },
    { Name: "First Four-Day Week", Value: "FirstFourDayWeek" },
  ],
  aptMenuModel: [
    { text: "New Event", iconCss: "e-icons e-plus", id: "Add" },
    {
      text: "New Recurring Event",
      iconCss: "e-icons e-repeat",
      id: "AddRecurrence",
    },
    { text: "Today", iconCss: "e-icons e-timeline-today", id: "Today" },
    { text: "Edit Event", iconCss: "e-icons e-edit", id: "Save" },
    {
      text: "Edit Event",
      id: "EditRecurrenceEvent",
      iconCss: "e-icons e-edit",
      items: [
        { text: "Edit Occurrence", id: "EditOccurrence" },
        { text: "Edit Series", id: "EditSeries" },
      ],
    },
    { text: "Delete Event", iconCss: "e-icons e-trash", id: "Delete" },
    {
      text: "Delete Event",
      id: "DeleteRecurrenceEvent",
      iconCss: "e-icons e-trash",
      items: [
        { text: "Delete Occurrence", id: "DeleteOccurrence" },
        { text: "Delete Series", id: "DeleteSeries" },
      ],
    },
  ],
  aptTimeScaleModel: { enable: true, interval: 10, slotCount: 1 },
  aptTimeRef: {
    enus: "en-US",
    utc: "UTC",
    calendarId: "CalendarId",
    equal: "equal",
    hm: "Hm",
    off: "Off",
    scheduleoverViewTime: ".schedule-overview #timezoneBtn",
    innerHtml:
      '<span class="e-btn-icon e-icons e-time-zone e-icon-left"></span>',
  },
  aptHeaderStyle: {
    date: "date",
    full: "full",
    cell: "cell",
    center: "center",
    colorCode: "#919191",
    calenderColorCode: "#3f51b5",
    calenderbgColor: "#FFFFFF",
    addAppointment: "Please select another slot",
    appointmentDetails: "Appointment Details",
  },
  aptExcelDisplay: ["Id", "Subject", "StartTime", "EndTime", "CalendarId"],
  aptSlotStatus: {
    busy: "BOOK",
    free: "free",
    Hold:"hold",
    eventBooked: "Event booked",
    eventScheduled: "Event Scheduled",
    mrn: "MRN:",
  },
  aptpatientType: {
    code: "REG",
    desc: "registered",
    system: "EN",
  },
  aptpatientTypeTemp: {
    code: "TEMP",
    desc: "temporary",
    system: "EN",
  },
  appointmentListStatic: {
    appointmentList: "AppointmentList",
    height: "150px",
    width: "width",
    reschedule: "reschedule",
  },
  appoinmenList: [
    "check",
    "Appno",
    "MRN",
    "Patient",
    "PatientDeatils",
    "SpecialReq",
    "Package",
    "AppointmentDate",
    "bookingType",
    "Doctor",
    "Speciality",
    "PatientType",
    "CheckIn",
    "Remarks",
    "Status",
    "Reason",
    "Print",
  ],
  reschuleList: [
    "Appno",
    "AppointmentDate",
    "PatientType",
    "MRN",
    "Patient",
    "PatientDeatils",
    "mobileNo",
    "bookingType",
    "Speciality",
    "Doctor",
    "Reason",
    "action",
  ],
  admissionFormRequest: "admissionRequest",
  PatientAudit:"PatientAudit",
  admissionForm:"admissionForm",
  apppointmentListDisplay: {
    booked: "booked",
    arrived: "ARRIVED",
    cancelled: "CANCELLED",
    rescheduled: "rescheduled",
    noShow: "NOSHOW",
    paymentReceived: "PAYMENTRECEIVED"
  },
  fileSaveRegisteration: "MRN",
  fileSaveEncounter: "ENCOUNTER",
  uploadType: "app",
  pendingGL : "PENDING GL",
  noSlotsAvailable:"No slots available",
  nextAvailblity:"Next Availablity is ",
  displayedColumnsPayers : [
    "Priority",
    "payerType",
    "Payer",
    "Tariff",
    "Contract",
    "AssociatedCompany",
    "GLType",
    "PolicyNumber",
    "EffectiveDate",
    "Status",
    "Action"
  ],
  displayColumnsEncounter : [
    "radio",
    "visitDate",
    "visitType",
    "visitNo",
    "department",
    "doctorName"
  ],
  searchweekDays: [
    { text: "Sunday", value: false },
    { text: "Monday", value: false },
    { text: "Tuesday", value: false },
    { text: "Wednesday", value: false },
    { text: "Thursday", value: false },
    { text: "Friday", value: false },
    { text: "Saturday", value: false },
  ],
  appointmentSearchList:[
    "dateTime",
    "slotDuration",
    "doctororDept"
  ],
  businessStatusList:{
    ARRIVED: "ARV",
    REQUESTED:"REQ",
    REJECTED: "REJ",
    ACKNOWLEDGED:"ACK",
    ADMITTED:"ADM",
    CANCELLED:"CNC",
    DISCHARGEINITIATED:"DSI",
    READYFORBILLING:"RFB",
    BILLINGCOMPLETED:"BLC",
    DISCHARGED:"DIS",
    CHECKOUT:"CHO",
  
  },
  displayedDashboardColumn: [
    "viewBill",
    "mrn",
    "ipdNo",
    "patientName",
    "patientGender",
    "doa",
    "admittingdept",
    "admittingdoctor",
    "ward",
    "bed",
    "los",
    "status",
  ],
  splRightCancelAdmission:"SP03",
  DISCHARGE_STATUS_ADMITTED: "ADMITTED",
  DISCHARGE_STATUS_CLINICALDISCHARGED: "CLINICALDISCHARGED",
  DISCHARGE_STATUS_FINALDISCHARGED: "FINALDISCHARGED",
  DISCHARGE_STATUS_CANCELLED: "CANCELLED",
  displayDashboardColumns: [
    "viewBill",
    "mrn",
    "ipdNo",
    "patientName",
    "patientGender",
    "doa",
    "admittingdept",
    "admittingdoctor",
    "ward",
    "bed",
    "los",
    "cancelDate",
    "status"
  ],
  displayedDashboardColumns: [
    "viewBill",
    "mrn",
    "ipdNo",
    "patientName",
    "patientGender",
    "doa",
    "admittingdept",
    "admittingdoctor",
    "ward",
    "bed",
    "los",
    "cancelDate",
    "dischargeDate",
    "status"
  ],
  displayedColumnsAccountDetails: [
    '#',
    'payerType',
    'payerName',
    'glStatus',
    'tariff',
    'contract',
    'glCoverageAmt'
  ],
  staffType: {
    system: "EN",
    code: "staff",
    display: "Staff",
  },
  studentType: {
    system: "EN",
    code: "student",
    display: "Student",
  },
  patientType: {
    system: "EN",
    code: "patient",
    display: "Patient",
  },
  displayedColumnsNextOfKinDetails: [
    'nokName',
    'relationship',
    'idType',
    'idNo',
    'mobileNo',
    'isGuarantor'
  ],
  displayPatientAuditList: [
    "auditDate",
    "user",
    "title",
    "name",
    "primaryType",
    "primaryNo",
    "secondoryType",
    "secondoryNo",
    "gender",
    "Dob",
    "vip",
    "deceased",
    "category",
    "nationality",
    "race"
  ],
  displayColumnsPendingOrders:[
    "isChecked",
    "Description",
    "Priority",
    "Doctor",
    "OrderDate",
    "OrderUser",
    "Remark"
  ],
  glRemarksdisplayedColumns: ["addedon", "addedby", "remarks"],
  glAttachmentdisplayedColumns: [
    "srNo",
  "fileName",
  "fileLocation",
  "description",
  "fileType",
  "action",],
};
export const wristbandOption = {
  selectedAdult:"Adult", 
  selectedChild:"Child",
}
