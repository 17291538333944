<nav class="navbar custom-navbar bg-faded main-header">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a
      title="View Menu"
        href="javascript:;"
        class="nav-link btnMenu"
        (click)="toggleSidebar.emit()"
      >
        <i class="hamburger-icon v2"> <span></span> </i>
      </a>
    </li>

    <li></li>
  </ul>
  <!-- <span class="navbar-heading hidden-xs-down">{{heading}} <i class="icon icon-arrows-right"></i>{{subheading}}</span>  -->

  <!-- <div style="cursor: pointer;">
    <button mat-mini-fab class="btnRefresh">
      <i
        class="material-icons"
        matTooltip="Refresh Page"
        (click)="refreshApp()"
      >
        refresh
      </i>
    </button>
  </div> -->
  <span class="navbar-heading hidden-xs-down">
    <span class="spanBreadcrumb" matTooltip="{{ heading }}"  *ngIf="isdynamicHeading" >
      {{ 
       heading && heading.length > 40
        ? (heading | slice: 0:40) + ".."
        : heading 
       }}</span>
    <span matTooltip="{{ dynamicHeading }}" *ngIf="!isdynamicHeading">
      {{
        dynamicHeading && dynamicHeading.length > 40
          ? (dynamicHeading | slice: 0:40) + ".."
          : dynamicHeading
      }}</span
    >
    <!-- <span> <i class="icon icon-arrows-right"></i></span>
   <span>{{subheading}}</span> -->
  </span>
  <span> </span> <span class="mr-auto"></span>

  <div class="master-search" *ngIf="searchList && searchList.length > 0">
    <mat-icon
      (click)="getKeySearch(searchForm.value)"
      matTooltip="Search"
      class="search-icon-magnify"
      >search</mat-icon
    >
    <input
      autofocus
      type="text"
      (ngModelChange)="isTyped = true"
      [placeholder]="'SearchHeader.placeholder' | translate"
      [(ngModel)]="searchValue"
      (focus)="showCloseIcon()"
      (keyup.enter)="getKeySearch(searchValue)"
    />
    <mat-icon
      class="search-icon-close"
      (click)="clearText()"
      matTooltip="Collapse"
      *ngIf="isTyped"
      >close</mat-icon
    >
  </div>
  <div
    class="master-dropdown"
    [matMenuTriggerFor]="masterSearch"
    *ngIf="searchList && searchList.length > 0"
    (blur)="isVisible = false"
  >
    <mat-icon
      (click)="isVisible = true"
      style="line-height: 36px;"
      [matTooltip]="'SearchHeader.expand' | translate"
      *ngIf="!isVisible && !isTyped"
    >
      arrow_drop_down</mat-icon
    >
    <mat-icon
      (click)="isVisible = false"
      style="padding-top: 20%;"
      [matTooltip]="'SearchHeader.collapse' | translate"
      *ngIf="isVisible && !isTyped"
    >
      arrow_drop_up</mat-icon
    >
  </div>
  <mat-menu
    class="menu-master"
    yPosition="above"
    [overlapTrigger]="false"
    #masterSearch="matMenu"
  >
    <div>
      <form
        #searchForm="ngForm"
        class="master-search-form"
        (ngSubmit)="setSearch(searchForm.value)"
      >
        <div
          (click)="$event.stopPropagation()"
          (keydown.tab)="$event.stopPropagation()"
          *ngFor="let search of searchList; let p_index = index"
          class="search-content-row"
        >
          <div
            *ngFor="let control of search.controls; let c_index = index"
            class="search-col search-col-{{ control.column }}"
          >
            <mat-radio-group
              *ngIf="control.type == 'radio'"
              [(ngModel)]="this[control.name]"
              name="{{ control.name }}"
            >
              <mat-radio-button
                *ngFor="let radio of control.radios; let r_index = index"
                value="{{ radio.value }}"
                (click)="radioOnClick(radio)"
                >{{ radio.text }}</mat-radio-button
              >
            </mat-radio-group>

            <mat-form-field *ngIf="control.type == 'date'" appearance="outline">
              <mat-label>{{ control.placeholder }}</mat-label>

              <input
                matInput
                [matDatepicker]="picker"
                [min]="minDate"
                placeholder="{{ control.placeholder }}"
                [(ngModel)]="this[control.name]"
                value="{{ control.value }}"
                name="{{ control.name }}"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <!-- <input type="text" placeholder="Select From Date - To Date" ngxDaterangepickerMd [(ngModel)]="selected"
                matInput [ranges]="ranges" [alwaysShowCalendars]="true" [linkedCalendars]="true"
                [locale]="{ applyLabel: 'ok', format: 'DD/MM/YYYY' }" /> -->
            </mat-form-field>

            <mat-form-field
              *ngIf="control.type == 'dateRange'"
              appearance="outline"
            >
              <mat-label>{{ control.placeholder }}</mat-label>

              <input
                type="text"
                #dateInput
                ngxDaterangepickerMd
                [lockStartDate]="false"
                placeholder="{{ control.placeholder }}"
                [(ngModel)]="selected1"
                matInput
                [ranges]="ranges"
                [alwaysShowCalendars]="true"
                [linkedCalendars]="true"
                [showClearButton]="true"
                autocomplete="off"
                [locale]="{ applyLabel: applyLabel, clearLabel: clearLabel, format: 'DD/MM/YYYY', monthNames: monthsArr, daysOfWeek: daysArr }"
                name="daterange"
              />
            </mat-form-field>

            <div *ngIf="error.isError" class="alert alert-danger">
              {{ error.errorMessage }}
            </div>

            <mat-form-field *ngIf="control.type == 'text'" appearance="outline">
              <mat-label>{{ control.placeholder }}</mat-label>

              <input
                matInput
                placeholder="{{ control.placeholder }}"
                [(ngModel)]="this[control.name]"
                name="{{ control.name }}"
              />
            </mat-form-field>

            <mat-form-field
              *ngIf="control.type == 'number'"
              appearance="outline"
            >
              <mat-label>{{ control.placeholder }}</mat-label>

              <input
                type="number"
                matInput
                placeholder="{{ control.placeholder }}"
                [(ngModel)]="this[control.name]"
                name="{{ control.name }}"
                value="{{ control.value }}"
              />
            </mat-form-field>

            <mat-form-field
              *ngIf="control.type == 'select'"
              appearance="outline"
            >
              <mat-label>{{ control.placeholder }}</mat-label>

              <mat-select
                id="{{ control.name }}"
                placeholder="{{ control.placeholder }}"
                [(ngModel)]="this[control.name]"
                name="{{ control.name }}"
                (selectionChange)="selectOnChange(control.name, $event.value)"
                value="{{ control.value }}"
                (focus)="selectOnClick(control, p_index, c_index)"
              >
                <mat-option>
                  <input
                    id="searchAutoComplete"
                    class="search-select-box"
                    (click)="$event.stopPropagation()"
                    (keyup)="dropdownOnSearch(control, $event)"
                    type="text"
                    [placeholder]="'RegistrationPage.search' | translate"
                    value="{{ control.searchValue }}"
                  />
                </mat-option>
                <mat-option [value]="">{{"RegistrationPage.none" | translate}}</mat-option>
                <div *ngIf="control.name == 'unit'">
                  <mat-option
                    *ngFor="let option of control.options"
                    [value]="option.id"
                  >
                    {{ option.desc }}
                  </mat-option>
                </div>
                <div *ngIf="control.name != 'unit'">
                  <mat-option
                    *ngFor="let option of control.options"
                    [value]="option"
                  >
                    {{ option.desc }}
                  </mat-option>
                </div>
                <!-- <ng-container *ngFor="let option of control.options">
                    <option *ngIf="[control.name]=='unit'"  [ngValue]="option.id" >{{ option.desc }}</option>
                    <option *ngIf="[control.name]!='unit'"  [ngValue]="option" >{{ option.desc }}</option>
                </ng-container>                -->
              </mat-select>
            </mat-form-field>


            <mat-form-field
              *ngIf="control.type == 'selectDoc'"
              appearance="outline"
            >
              <mat-label>{{ control.placeholder }}</mat-label>

              <mat-select
                id="{{ control.name }}"
                placeholder="{{ control.placeholder }}"
                [(ngModel)]="this[control.name]"
                name="{{ control.name }}"
                (selectionChange)="selectOnChange(control.name, $event.value)"
                value="{{ control.value }}"
                (focus)="selectOnClickDoc(control, p_index, c_index)"
              >
                <mat-option>
                  <input
                    id="searchAutoComplete"
                    class="search-select-box"
                    (click)="$event.stopPropagation()"
                    (keyup)="dropdownOnSearch(control, $event)"
                    type="text"
                    [placeholder]="'RegistrationPage.search' | translate"
                    value="{{ control.searchValue }}"
                  />
                </mat-option>
                <mat-option [value]="">{{"RegistrationPage.none" | translate}}</mat-option>
                <div *ngIf="control.name == 'unit'">
                  <mat-option
                    *ngFor="let option of control.options"
                    [value]="option.id"
                  >
                    {{ option.desc }}
                  </mat-option>
                </div>
                <div *ngIf="control.name != 'unit'">
                  <mat-option
                    *ngFor="let option of control.options"
                    [value]="option"
                  >
                    {{ option.desc }}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="search-content-row">
          <div class="search-col search-col-6">
            <div
              class="mt-10"
              (keydown.tab)="$event.stopPropagation()"
              (click)="$event.stopPropagation()"
            >
              <mat-checkbox name="checkbox_filter" [(ngModel)]="saveFilter"
                >{{ 'SearchHeader.saveSearch' | translate }}</mat-checkbox
              >
            </div>
          </div>
          <div class="search-col search-col-6 right">
            <button type="submit" class="btn-master-search" mat-button [matTooltip]="'SearchHeader.search' | translate" >
              <mat-icon>search</mat-icon>
            </button>
            <button
              type="button"
              class="btn-master-cancel"
              mat-button
              (click)="$event.stopPropagation(); clearSearch()"
              [matTooltip]="'SearchHeader.reset' | translate"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-menu>

  <mat-icon
    *ngIf="showQRScanButton"
    class="color-white menuModules mx-2"
    matTooltip="Scan QR Code" 
    (click)="openBarcodeScanDialog()"
  >
    center_focus_weak
  </mat-icon>

  <mat-icon
    matTooltip="Menu"
    [matMenuTriggerFor]="menu"
    class="color-white menuModules mx-2"
    >apps</mat-icon
  >
  <mat-menu
    #menu="matMenu"
    class="mat-menu-modal"
    yPosition="above"
    [overlapTrigger]="false"
  >
    <div class="mat-menu-modal menu-modal-list">
      <div *ngFor="let module of moduleList">
        <ng-container *ngIf="module.menuList.length > 0">
          <span class="category-name">{{ module.moduleName }}</span>
          <div class="menu-content-row" *ngFor="let menu of module.menuList">
            <ng-container *ngFor="let submenu of menu.list">
              <div class="search-col search-col-3 center">
                <div class="menu-item" (click)="menuOnClick(submenu)">
                  <img src="{{ submenu.image }}" />
                  <span>{{ submenu.name }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-menu>

  <mat-icon
    matTooltip="Notification"
    [matMenuTriggerFor]="notification"
    class="color-white menuModules mx-2"
  >
    notifications
  </mat-icon>
  <span class="spanNotificationCount" *ngIf="pushNotificationCount > 0">{{
    pushNotificationCount
  }}</span>
  <mat-menu
    #notification="matMenu"
    class="mat-menu-modal"
    yPosition="above"
    [overlapTrigger]="false"
  >
    <div (click)="$event.stopPropagation();">
      <app-push-notification-list></app-push-notification-list>
    </div>
  </mat-menu>
<!-- 
  <mat-icon
    *ngIf="isCounterFlg"
    matTooltip="Cash Counter"
    [matMenuTriggerFor]="cashCounter"
    class="color-white menuModules ml-15 span-flex"
  >
    account_circle
  </mat-icon> -->
  <mat-menu
    #cashCounter="matMenu"
    class="mat-menu-modal"
    yPosition="above"
    [overlapTrigger]="false"
  >
    <app-cash-counter></app-cash-counter>
  </mat-menu>

  <!-- <mat-icon [matMenuTriggerFor]="menuUser" class="color-white menuUserSettings ml-15">person</mat-icon> -->
  <div
    [matMenuTriggerFor]="menuUser"
    matTooltip="{{ userTypeName }} ({{ unitName }})"
    class="color-white menuUserSettings ml-15 span-flex"
  >
    <img
      *ngIf="userImage" alt=""
      [src]="userImage"
      class="color-white img-round userImageClick span-inline"
    />
    <img
    *ngIf="!userImage" alt=""
    [src]= "imageUrl ? imageUrl : '../assets/images/user-black.png'" 
    class="userImageClick"
  />
    <span class="span-inline">
      <span>{{ loginName }}</span> <br />
      <span class="span-unit">UNIT: {{ unitCode?unitCode:unitCodeValue }}</span>
    </span>
    <mat-icon class="profile-down-arrow">keyboard_arrow_down</mat-icon>
  </div>
  <!-- <img src="../assets/images/user.png" [matMenuTriggerFor]="menuUser" class="color-white menuUserSettings ml-15"> -->
  <mat-menu
    #menuUser="matMenu"
    class="mat-menu-modal menuUserProfile"
    yPosition="above"
    [overlapTrigger]="false"
  >
    <div class="divProfile">
      <div class="imgProfile">
        <img *ngIf="!userImage" class="img-round" [src]= "imageUrl ? imageUrl : '../assets/images/user-black.png'" />
        <img *ngIf="userImage" class="img-round" [src]="userImage" />
      </div>
      <div class="userProfileDetails">
        <div class="name">{{ loginName }}</div>
        <div class="designation">{{ userTypeName }}</div>
        <div class="unit">{{ unitName }}</div>
      </div>
      <div class="userProfileUpdate cursorPointer">
        <mat-icon
          class="pull-right icon-edit-profile"
          matTooltip="Update"
          (click)="openProfileDialog()"
          >create
        </mat-icon>
      </div>
    </div>

    <div class="mt-10">
      <div class="mt-10">
        <div class="organization">Organization : {{ (masterHeaderOrg && masterHeaderOrg.orgCode)? masterHeaderOrg.orgCode : "" }}</div>
        <!-- <mat-form-field
          class="full-width"
          (click)="$event.stopPropagation()"
          appearance="outline"
        >
          <mat-label>Organization</mat-label>
          <mat-select [(ngModel)]="masterHeaderOrg" name="org" (selectionChange)="orgChanged(masterHeaderOrg)">
            <mat-option *ngFor="let org of orgs" [value]="org">{{
              org.orgCode
            }}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="mt-10">
        <mat-form-field
          class="full-width"
          (click)="$event.stopPropagation()"
          appearance="outline"
        >
          <mat-label>Unit</mat-label>
          <mat-select [(ngModel)]="masterHeaderUnit" name="unit" (selectionChange)="unitChanged(masterHeaderUnit)">
            <mat-option *ngFor="let unit of orgUnits" [value]="unit.unitCode">{{
              unit.unitName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="mt-10">
        <mat-form-field
          class="full-width"
          (click)="$event.stopPropagation()"
          appearance="outline"
          >
          <mat-label>Room</mat-label>
          <mat-select [(ngModel)]="masterRoomData" name="room" (selectionChange)="changeUnitRoom(masterRoomData)">
          <mat-option *ngFor="let room of rooms" [value]="room.desc">{{
          room.desc
          }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-10">
        <mat-form-field
          class="full-width"
          (click)="$event.stopPropagation()"
          appearance="outline"
        >
          <mat-label>Store</mat-label>
          <mat-select [(ngModel)]="masterHeaderStore" name="store">
            <mat-option *ngFor="let store of storeList" [value]="store">{{
              store.storeDesc
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="mt-10">
        <mat-form-field
          class="full-width"
          (click)="$event.stopPropagation()"
          appearance="outline"
        >
          <mat-label>Language</mat-label>
          <mat-select [(ngModel)]="selected">
            <mat-option *ngFor="let language of languageList" [value]="language.code">
              {{ language.desc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-10 center">
        <button (click)="changeSettings()" mat-mini-fab class="btnChangeUnit">
          <mat-icon matTooltip="Change">swap_vert</mat-icon>
        </button>
        &nbsp;
        <button mat-mini-fab (click)="openSettingsDialog()" class="btnCyan">
          <mat-icon matTooltip="Settings">settings</mat-icon>
        </button>
        &nbsp;
        <button mat-mini-fab (click)="signout()" class="btnLogout">
          <mat-icon matTooltip="Logout">exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  </mat-menu>
</nav>
