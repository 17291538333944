import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MICROSERVICES, MICROSERVICESURL } from '../../properties/qms-url-properties';
import { MatDialog } from '@angular/material/dialog';
import { GeneralUrlService } from '../../services/generalUrl.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { AppBaseService } from 'medcare-core-ui';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-token-generation',
  templateUrl: './token-generation.component.html',
  styleUrls: ['./token-generation.component.scss'],
  providers: [GeneralUrlService]
})
export class TokenGenerationComponent implements OnInit {
  @ViewChild(NgxScannerQrcodeComponent, { static: false }) scanner: NgxScannerQrcodeComponent;
  currentStep: any = 'Step 1'

  _onDestroy: Subject<boolean> = new Subject<boolean>();
  services: any;
  selectedService: any;
  patientMrn;
  tokenDetails: any;
  fullName: any;
  email;
  mobileNo;
  public qrOutput: string = "";
  
  constructor(
    public dialog: MatDialog,
    private generalApiService: GeneralUrlService,
    public snackBar: MatSnackBar,
    public appBaseService: AppBaseService,
    public changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getServiceList();
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  getServiceList() {
    this.generalApiService
      .getTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.SERVICE_LIST)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res && res.length) {
          this.services = res.filter(data => data.isWalkIn);
        }
      });
  }

  getSearchResultPatient() {
    let reqPayload = {
      mrn: this.patientMrn ? this.patientMrn : "",
      identificationNo: "",
    };
    this.appBaseService.setResourceURL(MICROSERVICES.ANCILLARY_SERVICE);
    this.appBaseService
      .postResource(MICROSERVICESURL.PATIENT_SEARCH, reqPayload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any[]) => {
        if (res && res.length) {
          this.addVisitor("patient", res[0]);
        } else {
          this.snackBar.open(
            "Error", "Patient Not Found",
            {
              duration: 1000,
            }
          );
        }
      })
  }
  
  generateTokenForVisitor() {
    this.addVisitor("visitor");
  }

  addVisitor(against, requestData?) {
    let data;
    if (against == "visitor") {
      data = {
        "qmServiceCode": this.selectedService?.code,
        "visitorName": (this.fullName ? this.fullName : "").toUpperCase(),
        "visitorMobileNo": this.mobileNo,
        "visitorEmail": this.email,
        "qmLabelIdList": []
      }
    } else {
      data = {
        "qmServiceCode": this.selectedService?.code,
        "patientId": requestData.patientId,
        "qmLabelIdList": []
      }
    }
    this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.ADD_VISITOR, data)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        this.tokenDetails = res
        this.currentStep = "token"
        this.snackBar.open(
          "Sucess", "Visitor Added Sucessfully",
          {
            duration: 1000,
          }
        );
        setTimeout(() => {
          this.clearAll();
        }, 10000);
      });
  }

  updateStep(step: string) {
    if(this.scanner && this.scanner.isStart) this.scanner.stop();
    this.currentStep = step;
    this.changeDetector.detectChanges();
    if(this.currentStep == 'patientCard') this.scanner.start();
  }

  onCameraStart(action) {
    if (!this.scanner) this.scanner = action;
    action.start();
  }

  onBarcodeScanned(action, $event): void {
    this.qrOutput = $event ? $event[0].value : null;
    if (this.qrOutput) {
      this.patientMrn = this.qrOutput;
      this.getSearchResultPatient();
    }
    if (this.scanner) this.scanner.stop();
    action.stop();
  }

  clearAll() {
    this.selectedService = undefined;
    this.currentStep = "Step 1";
    this.patientMrn = undefined;
    this.fullName = undefined;
    this.mobileNo = undefined;
    this.email = undefined;
    this.tokenDetails = undefined;
  }
}
