import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PushNotificationService } from "../../modules/push-notification/service/push-notification.service";
import { SearchService } from "../../services/search.service";
import { AppBaseService } from "../../services/http.service";
import { EnvoirnmentService } from "../../services/envoirnment.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Moment } from "moment";
import * as temp from "moment";
const moment = temp["default"];
import { properties } from "./header.properties";
import { AuthService } from "./../../services/auth.service";
import { DatePipe } from "@angular/common";
import localForage from "localforage";
import { Subject, ReplaySubject, Subscription, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";
import { CookieService } from "ngx-cookie-service";
import { PushNotificationComponent } from "../../modules/push-notification/controllers/push-notification/push-notification.component";
import { BarcodeScannerComponent } from "../barcode-scanner/barcode-scanner.component";
import { QRScanTypes } from "../../enums/configuration-modules.enum";
import { ApplicationConfigService } from "../../services/application-config.service";
import { FormControl, ValidatorFn } from "@angular/forms";
import { shortcutNavigation } from "../../regproperties/opd-navigation-properties";
import { MasterService } from "../../services/masters.service";
import { MASTERSSERVICES } from "../../constants/master-service";

export interface DialogData {
  email: string;
  userId: number;
  userName: string;
  imageUrl:string;
  encounterId: any;
  mrnno: any;
  patientId: any;
  alldata: {
    patientId: string;
    y;
    mrnno: string;
    id: string;
  };
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [AuthService, DatePipe, PushNotificationComponent],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  private subscriptionList = new Subscription();

  @ViewChild("dateInput") dateFilter: ElementRef;
  selected1: { startDate: Moment; endDate: Moment };
  ranges: any = {};
  loginName: string = "";
  emailId:string="";
  userImage: string = "";
  units: any[] = [];
  rooms: any[] = [];
  orgUnits: any[] = [];
  orgs: any[] = [];
  masterHeaderOrg: any;
  storeList: any[] = [];
  masterHeaderUnit: string;
  masterRoomData: string;
  selectedUnit: any;
  masterHeaderStore: any;
  userTypeName: string = "";
  unitName: string = "";
  unitCode: string = "";
  unitCodeValue:string="";
  isVisible: boolean = false;
  error: any = { isError: false, errorMessage: "" };
  minDate: Date;
  selected: string = localStorage.getItem("lan");
  @Input() heading: string;
  dynamicHeading: string;
  @Input() subheading: string;
  @Output() toggleSidebar = new EventEmitter<void>();
  @Output() openSearch = new EventEmitter<void>();
  @Output() toggleFullscreen = new EventEmitter<void>();
  searchList: any[] = [];
  menuList: any = [];
  menuArray: any[] = [];
  public dropdownValues: any = {};
  public dropdownCodes: any = {};
  public moreMenu = false;
  public properties: any = properties;
  isPageLoad = true;
  corporationObj: string;
  corporations: any[] = ["t1", "t2"];
  queryUnitCode: any;
  queryPasscode: string;
  controls: SearchControl[] = [];
  saveFilter: boolean = false;
  moduleList: any[];
  searchValue: string = "";
  public isTyped = false;
  public pushNotificationCount: number = 0;
  languageList: any[] = [];

  //flags
  isCounterFlg: boolean = false;
  monthsArr = [];
  daysArr = [];
  applyLabel: string = "";
  clearLabel: string = "";
  previousUrl: string = "";

  showQRScanButton: boolean = false;
  isdynamicHeading: boolean =false;
  QRScreenList: string[] = [
    // OPD Screens
    "/OPD/patient/patient-encounter",
    "/OPD/patient/patient-sampleregistration",
    "/OPD/patient/patient-merge",
    "/OPD/patient/patient-block",
    "/OPD/updateSponsor",
    
    // ADT Screens
    "/adt/admission-request-form",
    "/adt/admission-form-new",
    "/adt/new-born",

    // Billing Screens
    "/billing/bill/opbill",
    "/billing/bill/opcharge",
    "/billing/bill/debit-note",
    "/billing/bill/ipcharge",
    "/billing/bill/ipbill",
    "/billing/bill/credit-note",
    "/billing/bill/patient-ledger",
    "/billing/bill/splitBill",
    "/billing/bill/splitBill",
    "/billing/bill/charge-verification",
    "/billing/chargeConversion",
    "/billing/deposit/deposit",
    "/billing/refund/refund",
    "/billing/glapproval/glattachment",
    "/billing/add-bill-estimate-new",
    "/billing/bill/class-upgrade",

    // Pharmacy Screens
    "/pharmacy/medicationhistory-dashboard",
    "/pharmacy/refillmedication-dashboard",
    "/pharmacy/walkinsales-dashboard",
    "/pharmacy/patientconsumption-dashboard",
    "/pharmacy/patientconsumption/addnew",

    // Pharmacy (MOH) Screens
    "/moh/medicationhistory-dashboard",
    "/moh/refillmedication-dashboard",
    "/moh/walkinsales-dashboard",
    "/moh/patientconsumption-dashboard",
    "/moh/patientconsumption/addnew"
  ];
  userId: number;
  imageUrl:string;

  constructor(
    private router: Router,
    private searchService: SearchService,
    public baseservice: AppBaseService,
    public dialog: MatDialog,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private env: EnvoirnmentService,
    private pushNotificationService: PushNotificationService,
    private langService: MultiLanguageService,
    private cookieService: CookieService,
    private pushNotification: PushNotificationComponent,
    private applicationConfigService: ApplicationConfigService
  ) {
    const remove = router.events.subscribe((res) => {
      this.clearText();
      if (this.moduleList != undefined) this.loadHeading(this.menuArray);

      this.removeSearch();
    });
    this.subscriptionList.add(remove);

    this.route.queryParams.subscribe((params) => {
      if (params["unitCode"]) {
        this.queryUnitCode = atob(params["unitCode"]);
      }
      if (params["passcode"]) {
        this.queryPasscode = atob(params["passcode"]);
      }
    });

    this.subscriptionList.add(
      this.pushNotificationService.unreadPushNotificationCount$.subscribe(
        (res) => {
          this.pushNotificationCount = res;
        }
      )
    );
  }

  ngOnInit() {
    this.checkIsCounter();
    this.manageLoginData();
    this.initSearch();
    this.getlanguagedata();

    this.langService.isCacheLoaded("calendarMonths").then((content: any) => {
      for (let key in content) {
        this.monthsArr.push(content[key]);
      }
    });
    this.langService.isCacheLoaded("calendarDays").then((content: any) => {
      for (let key in content) {
        this.daysArr.push(content[key]);
      }
    });
    this.langService.isCacheLoaded("calendar").then((content: any) => {
      this.applyLabel = content["done"];
      this.clearLabel = content["clear"];
      this.ranges = {
        [content["today"]]: [moment(), moment()],
        [content["yesterday"]]: [
          moment().subtract(1, "days"),
          moment().subtract(1, "days"),
        ],
        [content["lastSevenDays"]]: [moment().subtract(6, "days"), moment()],
        [content["lastThirtyDays"]]: [moment().subtract(29, "days"), moment()],
        [content["thisMonth"]]: [
          moment().startOf("month"),
          moment().endOf("month"),
        ],
        [content["lastMonth"]]: [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      };
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.subscriptionList.unsubscribe();
  }

  /**
   * OPD MODULE NAVIGATIONS
   * 
   * @param event 
   */
  // @HostListener('window:keydown.control.alt.o', ['$event'])
  // opModuleNavigation(event: KeyboardEvent) {
  //   event.preventDefault();
  //   this.router.navigate([shortcutNavigation.OPD_MODULE]);
  // }

  // @HostListener('window:keydown.control.r', ['$event'])
  // opModuleNavigationRegistration(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/OPD/'))
  //   this.router.navigate([shortcutNavigation.REGISTRATION_SCREEN]);
  // }

  // @HostListener('window:keydown.control.a', ['$event'])
  // opModuleNavigationAppointment(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/OPD/'))
  //   this.router.navigate([shortcutNavigation.APPOINTMENT_SCREEN]);
  // }

  /**
   *BILLING MODULE NAVIGATIONS
   *
   * @param {KeyboardEvent} event
   * @memberof HeaderComponent
   */
  // @HostListener('window:keydown.control.alt.b', ['$event'])
  // billingModuleNavigation(event: KeyboardEvent) {
  //   event.preventDefault();
  //   this.router.navigate([shortcutNavigation.BILLING_MODULE]);
  // }
  
  // @HostListener('window:keydown.control.o', ['$event'])
  // billingModuleNavigationOPBill(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/billing/'))
  //   this.router.navigate([shortcutNavigation.OP_BILL_SCREEN]);
  // }
  
  // @HostListener('window:keydown.control.d', ['$event'])
  // billingModuleNavigationDebitNote(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/billing/'))
  //   this.router.navigate([shortcutNavigation.DEBIT_NOTE_SCREEN]);
  // }

  // @HostListener('window:keydown.control.n', ['$event'])
  // billingModuleNavigationCreditNote(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/billing/'))
  //   this.router.navigate([shortcutNavigation.CREDIT_NOTE_SCREEN]);
  // }

  // @HostListener('window:keydown.control.i', ['$event'])
  // billingModuleNavigationIPCharge(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/billing/'))
  //   this.router.navigate([shortcutNavigation.IP_CHARGE_SCREEN]);
  // }
  
  // @HostListener('window:keydown.control.b', ['$event'])
  // billingModuleNavigationBillSummary(event: KeyboardEvent) {
  //   event.preventDefault();
  //   if(this.router.url.includes('/billing/'))
  //   this.router.navigate([shortcutNavigation.BILL_SUMMARY_SCREEN]);
  // }

  checkIsCounter() {
    this.authService.getCounterList().then((value: any) => {
      this.isCounterFlg = value && value.length > 0 ? true : false;
    });
  }

  manageLoginData() {
    try {
      this.authService.getData().then((user: any) => {
        if (user) {
          this.loginName = user.employeeDetails?user.employeeDetails.employeeName:"";
          //user.userName;
          this.emailId = user.userEmailId;
          this.userId=user.userId;
          this.imageUrl= user.imageUrl ? user.imageUrl : (user.imageUrl ? user.imageFile : "");
          if (user.userImage)
            this.userImage = "data:image/jpg;base64," + user.userImage;

          let units = user.userUnitList;
          if (units && units.length > 0) {
            this.orgs = this.orderByOrg(units);
            this.units = units;
            this.authService.getUnit().then((unit: any) => {
              if (unit) {
                let defaultUnit = units.find(
                  (i) => i.unitCode == unit.unitCode
                );
                if (defaultUnit.userRole && defaultUnit.userRole.userMenuList)
                  this.loadAllMenu(defaultUnit.userRole.userMenuList);

                if (!defaultUnit) defaultUnit = units[0];
                this.selectedUnit = defaultUnit;

                if (defaultUnit) this.loadDefault(defaultUnit);
                this.getCabinMaster();
              }
            });
            this.authService.getRole().then((role: any) => {
              if (role) {
                this.userTypeName = role.roleName;
              }
            });
          } else if (this.queryPasscode) {
            this.routeLogin(this.queryPasscode);
          } else {
            this.signout();
          }
          this.getStoreList();
        }
      });
    } catch (ex) {
      this.signout();
    }
  }

  getCabinMaster() {
    this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.baseservice
      .getResource(
        MASTERSSERVICES.CABIN_MASTER
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.length > 0) {
          this.rooms = [];
          data.forEach((el) => {
            this.rooms.push({ code: el.code, desc: el.desc });
          });
          localStorage.setItem("roomName", this.rooms[0].desc);
        }
      });
  }

  getStoreList() {
    this.authService.getStoreList().then((stores: any[]) => {
      this.storeList = stores;
      if (stores.length > 0) {
        let selectedStoreId = localStorage.getItem("storeId");
        if (selectedStoreId) {
          selectedStoreId = atob(selectedStoreId);

          let store = stores.find((i) => i.storeId == selectedStoreId);
          if (store) {
            this.masterHeaderStore = store;
          } else {
            let defaultStore = stores.find((item) => {
              return item.isDefault == true;
            });
            this.setDefaultStore(defaultStore);
          }
        } else {
          let defaultStore = stores.find((item) => {
            return item.isDefault == true;
          });
          this.setDefaultStore(defaultStore);
        }
      }
    });
  }

  loadDefault(unit) {
    let org = this.orgs.find((data) => {
      return data.id == unit.orgId;
    });
    this.masterHeaderOrg = org;
    this.orgChanged(org);
    let defaultUnit = this.orgUnits.find((data) => {
      return data.unitCode == unit.unitCode;
    });
    this.masterHeaderUnit = defaultUnit.unitCode;
    this.unitName = defaultUnit.unitName;
    this.unitCode = defaultUnit.unitCode;
    this.unitCodeValue= defaultUnit.unitCode;
  }

  orgChanged(org) {
    this.orgUnits = org.units;
    this.masterHeaderUnit = "";
    this.masterRoomData = "";
    this.unitName = "";
    this.unitCode = "";
  }

  unitChanged(unit) {
    let unitData = this.units.find((data) => {
      return data.unitCode == unit;
    });
    this.unitName = unitData.unitName;
    this.unitCode = unitData.unitCode;
  }

  changeUnitRoom(room) {
    localStorage.setItem("roomName", room);
  }

  orderByOrg(units) {
    let orgs: any[] = [];
    units.forEach((element, index) => {
      if (index == 0) {
        orgs.push(this.pushElement(element));
        return false;
      }

      if (index != 0) {
        let isPresent = orgs.findIndex((ele) => {
          return ele.id == element.orgId;
        });

        if (isPresent >= 0) {
          let unit = {
            unitCode: element.unitCode,
            id: element.unitId,
            unitName: element.unitDescription,
            status: true,
          };
          orgs[isPresent].units.push(unit);
          return false;
        }

        if (isPresent < 0) orgs.push(this.pushElement(element));
        return false;
      }
    });
    return orgs;
  }

  pushElement(data) {
    let obj = {
      orgCode: data.orgCode,
      status: data.active,
      id: data.orgId,
      units: [],
    };
    let unit = {
      unitCode: data.unitCode,
      id: data.unitId,
      unitName: data.unitDescription,
      status: true,
    };
    obj.units.push(unit);
    return obj;
  }

  setDefaultStore(station) {
    this.masterHeaderStore = station;
    localStorage.setItem("storeId", btoa(station.storeId));
    localStorage.setItem("storeCode", btoa(station.storeCode));
  }

  routeLogin(passcode) {
    this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
    this.baseservice
      .postResource("users/login", {
        passCode: passcode,
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        localStorage.setItem("lan", "en");
        localStorage.setItem("passcode", passcode);
        localStorage.setItem("isLoggedin", "true");

        let roles = [];
        data.userUnitRoleList.forEach((element) => {
          roles.push({
            id: element.role.id,
            roleName: element.role.roleName,
            roleCode: element.role.roleCode,
            unitId: element.unitId,
          });
        });
        data.userUnitRoleList = roles;
        let unit;
        let queryUnitCode = this.queryUnitCode;
        if (!queryUnitCode) {
          unit = data.userUnitList.find((data) => {
            return data.isDefault;
          });
        } else {
          unit = data.userUnitList.find((data) => {
            return data.unitCode == queryUnitCode;
          });
        }
        if (unit) {
          let unitRole: any = roles.find((i) => i.unitCode == unit.unitCode);
          data["userUnitRole"] = null;
          data["userUnit"] = null;
          localForage.setItem("unit", unit);
          localForage.setItem("role", {
            id: unitRole.id,
            roleName: unitRole.roleName,
            roleCode: unitRole.roleCode,
          });
          localStorage.setItem("userId", btoa(data.userId));
          localStorage.setItem("userName", btoa(data.userName));
          localStorage.setItem("unitId", btoa(unit.unitId));
          localStorage.setItem("orgId", btoa(unit.orgId));
          localStorage.setItem("unitCode", btoa(unit.unitCode));
          localStorage.setItem("orgCode", btoa(unit.orgCode));
          localForage.setItem("auth-data", data, (err) => {
            window.location.reload();
          });
        }
      });
  }

  changeSettings() {
    this.selectdata();
    this.unitChange();
  }

  unitChange() {
    try {
      let unit = this.units.find((i) => i.unitCode == this.masterHeaderUnit);
      if (unit) {
        this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
        this.baseservice
          .getResource(
            MASTERSSERVICES.CABIN_MASTER
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
            if (data.length > 0) {
              this.rooms = [];
              data.forEach((el) => {
                this.rooms.push({ code: el.code, desc: el.desc });
              });
              localStorage.setItem("roomName", this.rooms[0].desc);
            } else {
              this.rooms = [];
            }
          });
        this.authService.getData().then((data: any) => {
          if (data && data.userUnitList) {
            let unitRole: any = data.userUnitList.find(
              (i) => i.unitCode == unit.unitCode
            );
            if (unitRole && unitRole.userRole) {
              localForage.setItem("role", {
                id: unitRole.userRole.roleId,
                roleName: unitRole.userRole.roleDescription,
                roleCode: unitRole.userRole.roleCode,
              });

              localStorage.setItem("unitId", btoa(unit.unitId));
              localStorage.setItem("orgId", btoa(unit.orgId));
              localStorage.setItem("unitCode", btoa(unit.unitCode));
              if (this.masterHeaderStore)
                localStorage.setItem(
                  "storeId",
                  btoa(this.masterHeaderStore.storeId)
                );
              localForage.setItem("unit", unit).then((value: any) => {
                this.unitName = unit.unitDescription;
                this.unitCode = unit.unitCode;
                this.authService.getRole().then((role: any) => {
                  if (role) {
                    this.userTypeName = role.roleName;
                    window.location.reload();
                  } else {
                    this.signout();
                  }
                });
              });
            }
          }
        });
      }
    } catch (ex) {
      this.signout();
    }
  }

  initSearch() {
    try {
      const remove = this.searchService.getSearch.subscribe((list) => {
        if (
          list &&
          list.length > 0 &&
          list[0].controls.length > 0 &&
          list[0].controls[0] &&
          list[0].controls[0].type == "radio" &&
          list[0].controls[0].radios
        ) {
          this[list[0].controls[0].name] = list[0].controls[0].radios[0].value;
        }

        let pageName = this.router.url.split("?")[0];
        if (
          localStorage.getItem("filter" + pageName) != undefined &&
          localStorage.getItem("filter" + pageName) != null &&
          localStorage.getItem("filter" + pageName) != ""
        ) {
          let data = JSON.parse(localStorage.getItem("filter" + pageName));
          if (list && list.length > 0) {
            list.forEach((element) => {
              element.controls.forEach((control: SearchControl) => {
                this[control.name] = data[control.name];
              });
            });
            this.saveFilter = true;
            this.setSearch(data);
          }
        } else {
          this.saveFilter = false;
          this.clearSearch();
        }

        this.searchList = list;
      });
      this.subscriptionList.add(remove);
    } catch (ex) {}
  }

  removeSearch() {
    this.searchList = [];
  }

  selectOnChange(name, value) {
    if (name && value) this.dropdownValues[name] = value.id;
    if (name && value) this.dropdownCodes[name] = value.code;
  }

  radioOnClick(radio) {
    this.baseservice.setResourceURL("/");
    this.baseservice
      .getResource(radio.url)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data[0].uiConfig != null) {
          this.searchList = [];
          data[0].uiConfig.forEach((element) => {
            let controlList = [];
            element.controls.forEach((control: SearchControl) => {
              controlList.push({
                type: control.type,
                placeholder: control.placeholder, //this.properties.placeholder[control.placeholder],
                value: "",
                column: control.column,
                name: control.name,
                url: control.url ? control.url : "",
                selectType: "",
                radios: control.radios ? control.radios : [],
              });
            });
            this.searchList.push({ controls: controlList });
          });
        }
      });
      this.selected1 = null;
  }

  selectOnClick(control: SearchControl, parent, child) {
    let tempUrl = "";
    try {
      let hasParam = false;
      if (
        control.url &&
        control.url.includes("{") &&
        control.url.includes("{") &&
        !control.url.includes("{UnitMaster}")
      ) {
        let param = control.url.substring(
          control.url.lastIndexOf("{") + 1,
          control.url.lastIndexOf("}")
        );

        let dependentValue: string = this.dropdownValues[param].toString();
        if (dependentValue) {
          tempUrl = control.url.replace(/\{.*?\}/, dependentValue.trim());
        } else {
          tempUrl = control.url.replace(/\{.*?\}/, "''");
        }
        hasParam = true;
      } else if (control.methodName && control.methodName == "localForage") {
        if (control.name == "store") {
          let list = [];
          this.authService.getStoreList().then((stores: any) => {
            stores.forEach((element) => {
              list.push({
                id: element.stationId,
                code: element.stationCode,
                desc: element.stationName,
              });
            });
            if (!this.searchList[parent].controls[child].options) {
              this.searchList[parent].controls[child].options = list;
              if (hasParam) document.getElementById(control.name).click();
              hasParam = false;
            }
          });
        } else if (control.name == "unit") {
          let list = [];
          this.authService.getUnitList().then((stores: any) => {
            stores.forEach((element) => {
              list.push({
                id: element.id,
                code: element.unitCode,
                desc: element.unitName,
              });
            });
            if (!this.searchList[parent].controls[child].options) {
              this.searchList[parent].controls[child].options = list;
              if (hasParam) document.getElementById(control.name).click();
              hasParam = false;
            }
          });
        }
      }
      if (
        !this.searchList[parent].controls[child].options ||
        this.searchList[parent].controls[child].options.length == 0 ||
        hasParam
      ) {
        if (control.url) {
          if (
            (!control.url.includes("{") && !control.url.includes("{")) ||
            !tempUrl
          ) {
            tempUrl = control.url;
          }
          if (tempUrl != this.previousUrl || !hasParam) {
            this.previousUrl = tempUrl;
            this.baseservice.setResourceURL("/");
            this.baseservice
              .getResource(tempUrl)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                this.searchList[parent].controls[child].options = res;
                setTimeout(() => {
                  document.getElementById(control.name).click();
                  document.getElementById("searchAutoComplete").focus();
                }, 500);
              });
          }
        }
      }
    } catch (ex) {}
  }

  selectOnClickDoc(control: SearchControl, parent, child) {
    try {
      let dependentValue: string = this.dropdownCodes["Department"].toString();
      let payload = {
        limit: 0,
        unitCode: this.unitCode,
        departmentCode: dependentValue,
      };
      this.baseservice.setResourceURL("/");
      this.baseservice
        .postResource(control.url, payload)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          let list =
            res.payload && res.payload.employeeResponseList
              ? res.payload.employeeResponseList
              : [];
          this.searchList[parent].controls[child].options = list;
          setTimeout(() => {
            document.getElementById(control.name).click();
            document.getElementById("searchAutoComplete").focus();
          }, 500);
        });
    } catch (ex) {}
  }

  public menuOnClick(data) {
    try {
      if (data.module == "Clinical") {
        this.authService.getUser().then((res: any) => {
          let authValue=res.authorization.replace("Bearer",'').trim();
          this.cookieService.set(
            btoa("accessToken"),
            btoa(authValue),
            1,
            "/"
          );
          this.cookieService.set(
            btoa("USER_NAME"),
            btoa(res.userEmailId),
            1,
            "/"
          );
          window.open(this.env.clinicareUrl + data.url);
        });
      } else if (data.url == "AppointmentPortal") {
        window.open(this.env.appointmentPortalUrl, "_blank");
      } else {
        if (
          data.children &&
          data.children.length > 0 &&
          data.children[0].children &&
          data.children[0].children.length > 0
        ) {
          this.router.navigate([
            data.parent,
            data.children[0].location,
            data.children[0].children[0].location,
          ]);
        } else if (data.children && data.children.length > 0) {
          this.cookieService.set("isClinical", "true", 1, "/");
          this.router.navigate([data.parent, data.children[0].location]);
        } else {
          this.cookieService.set("isClinical", "true", 1, "/");
          this.router.navigate([data.parent]);
        }
      }
    } catch (ex) {}
  }

  public loadAllMenu(menuList) {
    let transformedMenu = [];
    menuList.forEach((element) => {
      let newObj = this.transformMenuItem(element);
      transformedMenu.push(newObj);
    });
    if (transformedMenu.length > 0) {
      this.menuArray = transformedMenu;
      this.loadHeading(this.menuArray);
      let menuList = transformedMenu;
      let menus = [];

      if (menuList) {
        let parents = menuList.filter((i) => i.menu.parentid == "0");
        if (parents) {
          parents.forEach((element) => {
            let childrenList = menuList
              .filter((i) => i.menu.parentid == element.menuId)
              .sort(
                (a, b) =>
                  parseFloat(a.menu.sequenceOrder.toString()) -
                  parseFloat(b.menu.sequenceOrder.toString())
              );

            let children = [];
            if (childrenList.length > 0) {
              let child = childrenList[0];
              let subChildren = [];
              let subChildrenList = menuList.filter(
                (i) => i.menu.parentid == childrenList[0].menuId
              );

              if (subChildrenList.length > 0) {
                let subchild = subChildrenList[0];

                subChildren.push({
                  displayText: subchild.menu.displayText,
                  icon: subchild.menu.icon,
                  children: [],
                  parent: subchild.menuId,
                  location: subchild.menu.location,
                  index: 0,
                });
              }

              children.push({
                displayText: child.menu.displayText,
                icon: child.menu.icon,
                children: subChildren,
                parent: element.menuId,
                location: child.menu.location,
                index: 0,
              });
            }

            menus.push({
              displayText: element.menu.displayText,
              children: children,
              location: element.menu.location,
              icon: element.menu.icon,
              index: element.menu.menuorder,
              menuid: element.menu.id,
              url: element.menu.url,
              module: element.menu.module,
            });
          });

          this.manageMenus(menus);
        }
      }
    }
  }

  transformMenuItem(item) {
    let obj = {
      id: "",
      active: true,
      createdDate: "",
      menu: {
        id: item["id"] ? item["id"] : "",
        status: item["status"] ? item["status"] : "",
        parentid: item["parentid"] ? item["parentid"] : "",
        menuorder: item["menuorder"] ? item["menuorder"] : "",
        location: item["location"] ? item["location"] : "",
        sequenceOrder: item["sequenceOrder"] ? item["sequenceOrder"] : "",
        displayText: item["header"] ? item["header"] : "",
        module: item["module"] ? item["module"] : "",
        url: item["location"] ? item["location"] : "",
        variablename: "default",
        icon: item["icon"] ? item["icon"] : "",
      },
      menuId: item["id"] ? item["id"] : "",
      createPermission: item["createPermission"]
        ? item["createPermission"]
        : "",
      updatePermission: item["updatePermission"]
        ? item["updatePermission"]
        : "",
      readPermission: item["readPermission"] ? item["readPermission"] : "",
      deletePermission: item["deletePermission"]
        ? item["deletePermission"]
        : "",
      createdByUserPermission: false,
      readByUserPermission: false,
      updateByUserPermisssion: false,
      deleteByUserPermission: false,
      viewReport: item["viewReport"] ? item["viewReport"] : "",
      reportPrint: item["reportPrint"] ? item["reportPrint"] : "",
      reportExport: item["reportExport"] ? item["reportExport"] : "",
      reportMail: item["reportMail"] ? item["reportMail"] : "",
      deActive: false,
    };
    return obj;
  }

  /**
   * To load heading
   * @param menu
   */
  loadHeading(menu) {
    this.isdynamicHeading = false  
    let dynamicText = "";
    if (menu) {
      let routeSplit: any = this.router.url.split("/");
      let routeList = routeSplit.splice(1, routeSplit.length + 1);
      let parentIds = [];
      routeList.forEach((element, index) => {
        let route = menu.filter((i) => i.menu.location == element);
        if (route) {
          if (index == 0 && route.length > 0) {
            route = route[0];
            parentIds.push(route.menu.id);
          } else {
            route = route.find((data) => {
              return (
                data.menu.parentid.toLowerCase() ==
                parentIds[index - 1]?.toLowerCase()
              );
            });
            if (route) parentIds.push(route.menu.id);
          }

          if (route) {
            if (index > 0)
              dynamicText = dynamicText + " > " + route.menu.displayText;
            else dynamicText = dynamicText + route.menu.displayText;
          }
          else
          {
            this.isdynamicHeading = true;
          }
        }
      });
    }
    this.dynamicHeading = dynamicText;
    this.checkQRButton();
  }

  /**
   *
   *
   * @memberof HeaderComponent
   */
  checkQRButton() {
    if(this.QRScreenList.find(i => i == this.router.url)) this.showQRScanButton = true;
    else this.showQRScanButton = false;
  }

  public qrOutputStr: string = "";
  /**
   *
   *
   * @memberof HeaderComponent
   */
  openBarcodeScanDialog() {
    const dialogRefConfirm = this.dialog.open(BarcodeScannerComponent, {
      width: "650px",
      data: { type: QRScanTypes.SEARCH },
    });

    dialogRefConfirm.afterClosed().subscribe((result) => {
      if(result) {
        let data = {
          mrn: result
        }
        this.setSearch(data);
      }
    });
  }

  /**
   * To manage Menus
   * @param menus
   */
  manageMenus(menus) {
    this.moduleList = [];
    let modules = menus
      .map((item) => item.module)
      .filter((value, index, self) => self.indexOf(value) === index);
    var index = modules.indexOf("Supplementary");
    if (index != undefined && index == 0) {
      const temp = modules[0];
      modules[0] = modules[1];
      modules[1] = temp;
    }

    modules.forEach((module) => {
      if (module) {
        let i = 1;
        let list = [];
        this.menuList = [];
        let menuList = menus.filter((i) => i.module == module);
        menuList.forEach((menu, index) => {
          if (menu) {
            list.push({
              name: menu.displayText,
              image: "../../../assets/images/master-icons/" + menu.icon,
              children: menu.children,
              parent: menu.location,
              index: index,
              menuid: menu.menuid,
              url: menu.url,
              module: menu.module,
            });
          }
          if (i == 4 || index == menuList.length - 1) {
            this.menuList.push({ moduleName: module, list: list });
            i = 1;
            list = [];
          } else {
            i++;
          }
        });

        this.moduleList.push({
          moduleName: module,
          menuList: this.menuList,
        });
      }
    });
  }

  dropdownOnSearch(control: SearchControl, event) {
    if (!control.masterList || control.masterList.length == 0)
      control.masterList = control.options;
    control.searchValue = event.srcElement.value;
    if (control.masterList.length > 0) {
      control.options = control.masterList.filter((i) =>
        i.desc.toString().toLowerCase().includes(control.searchValue.toLowerCase())
      );
    }
  }

  setSearch(data) {
    if (!data.start) data["start"] = "";
    if (!data.end) data["end"] = "";

    this.searchList.forEach((search) => {
      search.controls.forEach((control: SearchControl) => {
        if (!data[control.name]) data[control.name] = "";

        if (control.type == "dateRange") control.value = this.selected1;
        else if (control.type == "date" && data[control.name])
          data[control.name] = new Date(data[control.name]).toString().trim();
        else if (control.type == "text" && data[control.name])
          data[control.name] = data[control.name].toString().trim();
      });
    });

    let pageName = this.router.url.split("?")[0];
    if (this.saveFilter) {
      localStorage.setItem("filter" + pageName, JSON.stringify(data));
    } else {
      this.searchList.forEach((search) => {
        search.controls.forEach((control: SearchControl) => {
          this.selected1 = null;
          if (control.type == "dateRange") control.value = null;
          this[control.name] = "";
          control.searchValue = "";
          control.options=control.masterList;
        });
      });
      localStorage.setItem("filter" + pageName, "");
    }
    this.searchService.setResult(data);
  }

  private resultSubject = new Subject<any>();
  getResultF = this.resultSubject.asObservable();

  refreshApp() {
    this.router.navigate([this.router.url]);
  }

  clearSearch() {
    this.searchList.forEach((search) => {
      search.controls.forEach((control: SearchControl) => {
        this[control.name] = "";
        control.searchValue = "";
        control.options=control.masterList;
      });
    });
    this.selected1 = null;
  }

  signout() {
    localStorage.clear();
    localForage.clear();
    this.pushNotification.disconnect();
    this.cookieService.deleteAll();
    this.router.navigate([""]);
    this.applicationConfigService.screenConfiguration.listOfResources=[];
    this.applicationConfigService.moduleConfigurationList=[];
  }

  selectdata() {
    localStorage.setItem("lan", this.selected);
  }

  getlanguagedata() {
    this.langService.getLanguageListPromise().then((data: any[]) => {
      this.languageList = data;
      this.selected = localStorage.getItem("lan");
      this.translate.use(this.selected);
    });
  }

  openSettingsDialog(): void {
    this.dialog.open(settingsDialog, {
      width: "500px",
      height: "250px",
      data:{
        userName:this.loginName,
        email:this.emailId,
        userId:this.userId,
        imageUrl:this.imageUrl,
      }
    });
  }

  openProfileDialog(): void {
    this.router.navigate(["Dashboard/profile"]);
  }

  openUnitDialog(): void {
    this.dialog.open(unitDialog, {
      width: "400px",
      height: "auto",
    });
  }

  getKeySearch(data: string) {
    if (data && data.trim() != "") this.searchService.setElasticSearch(data);
  }

  clearText() {
    this.searchValue = "";
    this.isTyped = false;
  }

  showCloseIcon() {
    this.isTyped = this.searchValue ? true : false;
  }

  changeDropArrow() {
    this.isVisible = !this.isVisible;
  }
}

export class SearchControl {
  type: string = "";
  placeholder: string = "";
  value: any = {};
  column: number = 6;
  name: string = "";
  url: string = "";
  selectType: string = "";
  radios: string = "";
  methodName: string = "";
  options: any[] = [];
  masterList: any[] = [];
  searchValue:string="";
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "settings-dialog.html",
  styleUrls: ["./header.component.scss"],
})
export class settingsDialog implements OnInit , OnDestroy{
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  private subscriptionList = new Subscription();

  name:string;
  email:string;
  imageUrl:string;
  isClickOnKey:boolean=false;
  isClickOnSendOtp:boolean=false;
  convertedEmail:string;
  hide: boolean = true;
  hideConfirmKey: boolean = true;
  newKey: string = ''; 
  confirmKey: string = '';
  inputArray: number[] = [1, 2, 3, 4, 5, 6];
  userId:number;
  newKeyFormControl = new FormControl('', this.customRequiredValidator()); 
  confirmKeyFormControl = new FormControl('', this.customRequiredValidator());
  pin: string;
  constructor(
    public dialogRef: MatDialogRef<settingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar,
    public baseservice: AppBaseService,


  ) {}

  panelOpenState = false;
  ngOnInit(): void {
    this.name=this.data.userName;
    this.email=this.data.email;
    this.userId=this.data.userId;
    this.imageUrl=this.data.imageUrl;
  }
  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.subscriptionList.unsubscribe();
  }
  onSecurityKey(){
    this.isClickOnKey=true;
  }
  /**
   *click on send opt button
   *
   * @memberof settingsDialog
   */
  onSendOtpClick(){
    const newKey = this.newKeyFormControl.value;
    const confirmKey = this.confirmKeyFormControl.value;
    if(newKey && confirmKey){
    if(newKey === confirmKey){
      this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
      this.baseservice
        .postResource("user/sendOtpToSetupPersonalSecurityKey/"+this.userId,'')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          if(data){
            this.snackBar.open(data.message,'success',{duration:2000});
          this.isClickOnSendOtp=true;
          this.setDialogHeight("500px","350px");
          if(this.email){
            this.convertedEmail= this.convertEmail(this.email)
          }
        }
        })
        this.confirmKeyFormControl.setErrors(null);
  }else{
    this.confirmKeyFormControl.setErrors({ 'notMatched': true });
    this.snackBar.open('Please check entered key is same or not.','Validation',{duration:2000});
  }
}else{
  this.newKeyFormControl.markAsTouched();
  this.confirmKeyFormControl.markAsTouched()
  this.snackBar.open("Please Enter Key","Validation",{duration:2000});
}
  }
  /**
   *addjust dialog height 
   *
   * @param {string} width
   * @param {string} height
   * @memberof settingsDialog
   */
  setDialogHeight(width: string, height: string): void {
    this.dialogRef.updateSize(width,height);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  /**
   *convert email 
   *
   * @param {string} email
   * @return {*}  {string}
   * @memberof settingsDialog
   */
  convertEmail(email: string): string {
    const [username, domain] = email.split('@');
  
    const maskedUsername = 'x'.repeat(username.length - 3) + username.slice(-3);
    const maskedDomain = 'x'.repeat(domain.length - 3) + domain.slice(3);
  
    const maskedEmail = maskedUsername + '@' + maskedDomain;
    return maskedEmail;
  }
  onBackspace(id, nextInputIndex) {
    let nexInput = nextInputIndex - 1;
    let newID = id + nexInput;
    let textbox: any = document.getElementById(newID);
    if (textbox) {
      textbox.value = "";
      textbox.focus();
    }
  }
  loginMethod() {
    this.pin= "";
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox) this.pin = this.pin + textbox.value;
    }
  }
  /**
   *get otp input on input feilds
   *
   * @param {*} id
   * @param {*} nextInputIndex
   * @memberof settingsDialog
   */
  onInputEntry(id, nextInputIndex) {
    let nexInput = +nextInputIndex + 1;
    let newID = id + nexInput;
    let textbox = document.getElementById(newID);
    if (textbox) textbox.focus();
  }
  varifyOtp(){
    let payload={
      "primaryId": this.email,
      "otp": this.pin
    }
    this.baseservice.setResourceURL(MICROSERVICES.NOTIFICATION_SERVICE);
    this.baseservice
      .postResource("OTP/verify",payload)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if(data.statusCode=='500'){
          this.snackBar.open(data.message,"Please check OTP",{duration:2000});
        }else{
          this.saveSecurityKey()
        }
      })
  }
  saveSecurityKey(){
    const confirmKey = this.confirmKeyFormControl.value;
    this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
      this.baseservice
        .postResource("user/setupPersonalSecurityKey/"+confirmKey+"/"+this.userId,'')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          if(data){
            this.snackBar.open(data.message,"success",{duration:2000});
            this.dialogRef.close();
          }
        })
  }

  /**
   *costomised Required validation method
   * 
   * @return {*}  {ValidatorFn}
   * @memberof settingsDialog
   */
  customRequiredValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      const isEmpty = control.value === null || control.value === '';
      return isEmpty ? { 'required': true } : null;
    };
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "profile-dialog.html",
  styleUrls: ["./header.component.scss"],
})
export class profileDialog {
  constructor(
    public dialogRef: MatDialogRef<profileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  panelOpenState = false;

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "unit-dialog.html",
})
export class unitDialog {
  constructor(
    public dialogRef: MatDialogRef<unitDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  panelOpenState = false;
  onNoClick(): void {
    this.dialogRef.close();
  }
}
