export const MICROSERVICES = {
  BILLING_SERVICE: "/sdgt-billing-service/",
  MASTER_SERVICE: "/masters-service/",
  STOCK_SERVICE: "/stock-service/",
  PATIENT_INTEGRATION_SERVICE: "/patientintegration-service/",
  ANCILLARY_SERVICE: "/ancillary-service/",
};

export const MICROSERVICESURL = {
  CASH_COUNTER: "counter/getCounterByUnit",
  SERVICE_LIST: "service/getServiceByUnit",
  GET_QM_LIST: "qm/list",
  GET_HISTORY_BY_ID: "qm/getPatientHistoryDetailById/",
  GET_PATIENT_DETAIL_BY_ID: "qm/getDetailWithPatientInfoById/",
  GET_LABEL_LIST: "qmLabel/list?limit=10&offset=0",
  PATIENT_SEARCH: "patientSearch/get",
  ADD_VISITOR: "qm/save",
  UPDATE_VISIT: "qm/update",
  GET_COUNTER_BY_UNIT:"qm/getListByCounter"

};
