<div class="flex">
    <div class="flex-child" id="left-div">
        <div class="counter-header"> {{currentCounter?.counterName }}</div>
        <div style="padding: 10px;" *ngIf="currentCounter">
            <table>
                <tr>
                    <th>Token</th>
                    <th>MRN</th>
                    <th>Patient Name</th>
                    <th>Room No</th>
                    <th>Status</th>
                </tr>
                <tr *ngFor="let data of currentCounter.list">
                    <td>{{data.tokenNo}}</td>
                    <td>{{data.mrn}}</td>
                    <td>{{data.patientName}}</td>
                    <td>{{data.roomNo?data.roomNo:""}}</td>
                    <td>{{data.status}}</td>
                   
                </tr>
            </table>
        </div>
    </div>
    <div class="flex-child flex">
        <div class="flex-child" style="height: 80%">
            <div class="counter-header" style="margin-right: 270px;">Now Serving</div>
            <div *ngIf="currentServingList.length" class="serving-div" id="serving-div">
                <div class="serving" *ngFor="let servingToken of currentServingList">
                    <!-- <div
                    class="div-token {{
                        servingToken && servingToken.tokenNo == tokenNumber ? 'blink' : ''
                    }}"
                  > -->
                  <div class= "{{  servingToken && servingToken.tokenNo == tokenNumber ? 'blink' : ''}}"> 
                    <div class="token-label">{{servingToken.counter}}</div>
                    <div class="token-number">{{servingToken.tokenNo}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="flex-child" style="height: 50%">
            <iframe width="100%" height="100%"
                src="https://www.youtube.com/embed/Lz1U3iNY4UM?autoplay=1&&mute=1&&showinfo=0&&loop=1">
            </iframe>
        </div> -->
    </div>
</div>

<!-- src="https://www.youtube.com/embed/Lz1U3iNY4UM?autoplay=1&mute=1&&controls=0&&showinfo=0&&loop=1" -->