/*eslint-disable*/
import { Routes } from "@angular/router"
import {
  SigninComponent,
  SigninUumComponent,
  AdminLayoutComponent,
  DashboardComponent,
  AuthGuardGuard,
  NotFoundComponent,
  ProfileComponent,
} from "medcare-core-ui";
import { PdfViewerComponent } from "projects/medcare-core-ui/src/app/core/pdf-viewer/pdf-viewer.component";
import { environment } from "./../environments/environment";
import { MainDashboardComponent } from "projects/medcare-core-ui/src/app/main-dashboard/main-dashboard.component";
import { TokenGenerationComponent } from "./projects/queue-management/components/token-generation/token-generation.component";
import { CallingScreenComponent } from "./projects/queue-management/components/calling-screen/calling-screen.component";
import { PdfViewer2Component } from "projects/medcare-core-ui/src/app/core/pdf-viewer2/pdf-viewer2.component";
import { PdfViewer3Component } from "projects/medcare-core-ui/src/app/core/pdf-viewer3/pdf-viewer3.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: environment.isUserNameAndPasscodeLogin
      ? SigninUumComponent
      : SigninComponent,
  },
  {
    path: "notfound",
    component: NotFoundComponent,
  },
  {
    path: "Dashboard",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dash",
        component: MainDashboardComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: "pdfViewer",
        component: PdfViewerComponent,
      },
      {
        path: "pdfViewer2",
        component: PdfViewer2Component,
      },
      {
        path: "pdfViewer3",
        component: PdfViewer3Component,
      },
    ],
  },

  {
    path: "OPD",
    component: AdminLayoutComponent,
    children: (environment.Modules.opd) ? [{
      path: "",
      loadChildren: () => import('./projects/opd/opd.module').then(m => m.OpdModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "adt",
    component: AdminLayoutComponent,
    children: (environment.Modules.adt) ? [{
      path: "",
      loadChildren: () => import('./projects/adt/adt.module').then(m => m.AdtModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "master",
    component: AdminLayoutComponent,
    children: (environment.Modules.master) ? [{
      path: "",
      loadChildren: () => import('./projects/master/master.module').then(m => m.MasterModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "usermanagement",
    component: AdminLayoutComponent,
    children: (environment.Modules.user_management) ? [{
      path: "",
      loadChildren: () => import('./projects/user-management/user-management.module').then(m => m.UserManagementModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "inventory",
    component: AdminLayoutComponent,
    children: (environment.Modules.inventory) ? [{
      path: "",
      loadChildren: () => import('./projects/inventory/inventory.module').then(m => m.InventoryModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "procurement",
    component: AdminLayoutComponent,
    children: (environment.Modules.procurement) ? [{
      path: "",
      loadChildren: () => import('./projects/procurement/procurement.module').then(m => m.ProcurementModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "pharmacy",
    component: AdminLayoutComponent,
    children: (environment.Modules.pharmacy) ? [{
      path: "",
      loadChildren: () => import('./projects/pharmacy/pharmacy.module').then(m => m.PharmacyModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "diet-kitchen",
    component: AdminLayoutComponent,
    children: (environment.Modules.dietkitchen) ? [{
      path: "",
      loadChildren: () => import('./projects/dietkitchen/dietkitchen.module').then(m => m.DietkitchenModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "generalmaintenance",
    component: AdminLayoutComponent,
    children: (environment.Modules.general_maintenance) ? [{
      path: "",
      loadChildren: () => import('./projects/generalmaintenance/generalmaintenance.module').then(m => m.GeneralMaintenanceModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "housekeeping",
    component: AdminLayoutComponent,
    children: (environment.Modules.house_keeping) ? [{
      path: "",
      loadChildren: () => import('./projects/house-keeping/housekeeping.module').then(m => m.HouseKeepingModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "ambulance",
    component: AdminLayoutComponent,
    children: (environment.Modules.ambulance) ? [{
      path: "",
      loadChildren: () => import('./projects/ambulance/ambulance.module').then(m => m.AmbulanceModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "biomedicalwaste",
    component: AdminLayoutComponent,
    children: (environment.Modules.biomedic_waste) ? [{
      path: "",
      loadChildren: () => import('./projects/bmw/bmw.module').then(m => m.BmwModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "billing",
    component: AdminLayoutComponent,
    children: (environment.Modules.billing) ? [{
      path: "",
      loadChildren: () => import('./projects/billing/billing.module').then(m => m.BillingModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "doctor-share",
    component: AdminLayoutComponent,
    children: environment.Modules.doctor_share
      ? [
        {
          path: "",
          loadChildren: () => import('./projects/doctorshare/doctor-share.module').then(m => m.DoctorShareModule),
          canActivate: [AuthGuardGuard]
        }
      ]
      : []
  },
  {
    path: "lab",
    component: AdminLayoutComponent,
    children: (environment.Modules.lab) ? [{
      path: "",
      loadChildren: () => import('./projects/lab/lab.module').then(m => m.LabModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "reports",
    component: AdminLayoutComponent,
    children: (environment.Modules.mis_reports) ? [{
      path: "",
      loadChildren: () => import('./projects/misreports/misreports.module').then(m => m.MisreportsModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "mrd",
    component: AdminLayoutComponent,
    children: (environment.Modules.mrd) ? [{
      path: "",
      loadChildren: () => import('./projects/mrd/mrd.module').then(m => m.MrdModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "mortuary",
    component: AdminLayoutComponent,
    children: (environment.Modules.mortuary) ? [{
      path: "",
      loadChildren: () => import('./projects/mortuary/mortuary.module').then(m => m.MortuaryModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "crm",
    component: AdminLayoutComponent,
    children: (environment.Modules.CRM) ? [{
      path: "",
      loadChildren: () => import('./projects/crm/crm.module').then(m => m.CrmModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
 
  {
    path: "queuemanagement",
    component: AdminLayoutComponent,
    children: (environment.Modules.qms) ? [{
      path: "",
      loadChildren: () => import('./projects/queue-management/queue-management.module').then(m => m.QueueManagementModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "counselling-list",
    component: AdminLayoutComponent,
    children: (environment.Modules.counselling)? [{
      path: "",
      loadChildren: () => import('./projects/counselling/counselling.module').then(m => m.CounsellingModule),
      canActivate: [AuthGuardGuard]
    }]: []
  },
  {
    path: "daycare",
    component: AdminLayoutComponent,
    children: (environment.Modules.daycare) ? [{
      path: "",
      loadChildren: () => import('./projects/daycare/daycare.module').then(m => m.DaycareModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "radiology",
    component: AdminLayoutComponent,
    children: (environment.Modules.radiology) ? [{
      path: "",
      loadChildren: () => import('./projects/Radiology/radiology.module').then(m => m.RadiologyModule),
      canActivate: [AuthGuardGuard]
    }] : []
  },
  {
    path: "queuemanagement/qms-tokengeneration",
    component: TokenGenerationComponent
  }, 
  {
    path: "queuemanagement/qms-callingview",
    component: CallingScreenComponent
  }
];
